.article-details-container {
  padding: 1rem 0 1rem 0;
}

.article-details-container img {
  width: 100%;
  max-height: 34.375rem;
  object-fit: cover;
}

.article-details-container h2 {
  color: var(--primary-color);
}

.article-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 3rem;
}

.article-header a {
  display: flex;
  align-items: "center";
  gap: 1rem;
  text-decoration: none;
  color: inherit;
  min-width: 14rem;
}

.article-header a:hover {
  color: var(--primary-color);
}

.article-header img {
  width: 3rem;
  height: 3rem;
}
