.cart-item-container {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 5rem;
}

.cart-item-image {
  flex: 0 0 3.125rem;
  width: 3.125rem;
  height: 3.125rem;
  margin-right: 3rem;
}

@media (max-width: 48rem) {
  .cart-item-container {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;

    gap: 1rem;
  }

  .cart-item-container p,
  .cart-item-container b {
    text-align: center;
    margin: 0;
    flex: 0 0 0;
  }

  .cart-item-image {
    width: 9.375rem;
    height: 9.375rem;
    margin-right: 0;
  }

  .delete-icon-box {
    flex: 0 0 0;
  }
}

.cart-item-name {
  flex: 1;
  text-align: left;
  min-width: 6.25rem;
}

.selector-box {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 7.5rem;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-display {
  width: 1.25rem;
  text-align: center;
  margin: 0;
}

.cart-item-price {
  flex: 0 0 4.375rem;
  text-align: right;
  font-weight: bold;
}

.delete-icon-box {
  flex: 0 0 4.375rem;
  text-align: right;
}

.delete-icon {
  width: 1.5rem;
  text-align: right;
  cursor: pointer;
}
