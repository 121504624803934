.cart-item-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cart-container {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.cart-page {
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  margin: 1rem 0 1rem 0;
}

.buttons-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 48rem) {
  .buttons-box {
    flex-direction: column;
  }
}

.advertising-img {
  width: 20rem;
  cursor: pointer;
}

.clear-cart-button {
  display: flex;
  gap: 0.5rem;
}
