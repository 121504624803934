.footer {
  width: 100%;
  background: var(--primary-color);
  color: var(--white);
}

.footer-text {
  padding: 1rem;
  margin: 0;
}
