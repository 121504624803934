.header {
  width: 100%;
  height: 5rem;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.header-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.logo-img {
  margin: 1rem;
  cursor: pointer;
  height: 3rem;
  transition: height 0.3s ease;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.nav-link {
  color: var(--white);
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
}

.nav-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--secondary-color);
}

.nav-link:hover::after {
  width: 100%;
}

.cart-icon-container {
  position: relative;
  cursor: pointer;
}

.cart-icon {
  width: 2.25rem;
}

.cart-badge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: var(--secondary-color);
  color: white;
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: bold;
}

.cart-icon:hover {
  opacity: 0.8;
}

.nav-cart-box {
  display: flex;
  gap: 1rem;
}

.icons-box {
  display: flex;
  gap: 1rem;
  align-items: center;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .logo-img {
    height: 2rem;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--primary-color);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 1rem;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .nav-links.mobile-open {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
    cursor: pointer;
  }

  .mobile-menu-icon img {
    width: 2rem;
    height: 2rem;
  }
}

/* Desktop Styles */
@media (min-width: 769px) {
  .mobile-menu-icon {
    display: none;
  }
}
