.shop-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

@media (min-width: 48rem) {
  .shop-container {
    flex-direction: row;
    gap: 1rem;
    padding: 1rem 0 1rem 0;
  }
}
