.product-details-box {
  margin: 1rem 0 1rem 0;
}

.product-details-container {
  display: flex;
  justify-content: center;
  gap: 4rem;
  align-items: center;
}

.product-details-img {
  max-width: 28.125rem;
  max-height: 28.125rem;
}

.selector-box {
  display: flex;
  gap: 1rem;
}

.quantity-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.quantity-btn {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.quantity-btn:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.quantity-btn:hover:not(:disabled) {
  background-color: #2e6547;
}

.product-details-container b {
  margin-bottom: 1rem;
}

.quantity-input {
  width: 3rem;
  text-align: center;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.25rem;
}

.text-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.text-box p,
.text-box b,
.text-box h1 {
  margin: 0;
}

.text-box b {
  text-align: right;
}

@media (max-width: 768px) {
  .product-details-container {
    flex-direction: column;
    gap: 2rem;
  }

  .product-details-img {
    width: 100%;
    margin-top: 1rem;
  }

  .product-details-container b {
    margin-bottom: 1rem;
  }
}
