.product-gallery-container {
  position: relative;
  display: flex;
  align-items: center;
}

.gallery-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.gallery-arrow.left {
  left: 0.5rem;
}

.gallery-arrow.right {
  right: 0.5rem;
}

.gallery-arrow:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.product-gallery {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  padding: 1rem 0;
}

.product-gallery-item {
  flex: 0 0 auto;
  width: calc(100% / 2.5);
  max-width: 18.75rem;
}

/* Mobile Styles */
@media (max-width: 48rem) {
  .product-gallery {
    gap: 0.5rem;
    padding: 0.5rem 0;
  }

  .product-gallery-item {
    width: 18.75rem;
    max-width: none;
  }

  .gallery-arrow {
    width: 1.5rem;
    height: 1.5rem;
  }

  .gallery-arrow.left {
    left: 0.25rem;
  }

  .gallery-arrow.right {
    right: 0.25rem;
  }
}
