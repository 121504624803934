.products-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 48rem) {
  .products-list-container {
    justify-content: center;
  }
}
