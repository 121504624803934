.shop-blog-navbar {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Tablet Styles */
@media (max-width: 87.5rem) and (min-width: 48rem) {
  .nav-box-button-position {
    top: 50%;
    right: 55%;
  }
}

/* Mobile Styles */
@media (max-width: 48rem) {
  .shop-blog-navbar {
    flex-direction: column;
  }

  .nav-box-button-position {
    top: 50%;
    right: 50%;
  }
}

.nav-box {
  position: relative;
}

.nav-box-img {
  width: 100%;
}

.nav-box-title {
  cursor: pointer;
}

.nav-box-button-position {
  position: absolute;
  top: 80%;
  left: 20%;
  transform: translate(-50%, -50%);
}

.nav-button {
  width: 14rem;
  background: #ffffff;
  border: none;
  border-radius: 0.5rem;
  padding: 0.75rem;
  color: var(--primary-color);
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.nav-button:hover {
  background: var(--primary-color);
  color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.img-container {
  width: 100%;
  height: 22rem;
  object-fit: cover;
}
