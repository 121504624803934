.filter-panel-desktop {
  display: none;
}

.filter-panel-mobile {
  display: block;
}

@media (min-width: 768px) {
  .filter-panel-desktop {
    min-width: 12rem;
    display: block;
  }

  .filter-panel-mobile {
    display: none;
  }
}

.filter-panel-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  padding-top: 1.5rem;
  transition: left 0.3s ease;
  z-index: 1000;
}

.filter-panel-container.open {
  left: 0;
}

.category-list {
  list-style-type: none;
  padding: 0;
  margin-top: 1rem;
}

.category-item {
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.category-item:hover {
  background-color: #f1f1f1;
}

.category-item.active {
  font-weight: bold;
  color: var(--primary-color);
  background-color: #f1f1f1;
}

.filter-button {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0 1rem 0;
}
