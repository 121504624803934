.article-card-container {
  width: 100%;
  border: solid 1px rgb(232, 232, 232);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  cursor: pointer;
}

@media (max-width: 48rem) {
  .article-card-container {
    width: 80%;
    margin: auto;
  }
}

.aticle-title {
  text-align: center;
}

hr {
  color: var(--primary-color);
  width: 50%;
}

.aticle-date {
  text-align: center;
  padding: 0;
  margin: 0;
  color: var(--light-gray);
}

.article-img {
  width: 100%;
  height: auto;
  max-height: 34.375rem;
  object-fit: cover;
  margin: auto;
}
