.product-card-container {
  min-width: 15.5rem;
  max-width: 15.5rem;
  height: 24rem;
  border: solid 1px rgb(232, 232, 232);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  flex: 0 1 auto;
  box-sizing: border-box;
}

@media (max-width: 33.75rem) {
  .product-card-container {
    min-width: none;
    max-width: none;
    width: 90%;
  }
}

.product-img-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
}

.product-img {
  max-width: 9.375rem;
  max-height: 9.375rem;
}

.product-card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-price {
  padding: 0;
  margin: 0;
}

.product-card-container h3,
.product-card-container p {
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
