@import "../../styles/variables.css";

.button {
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button.fullWidth {
  width: 100%;
}

.button.primary {
  background-color: var(--primary-color);
  color: var(--white);
}

.button.secondary {
  background-color: var(--secondary-color);
  color: var(--white);
}

.button.outline {
  background-color: transparent;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.button.text {
  background-color: transparent;
  color: var(--primary-color);
}

.button.disabled {
  background-color: var(--light-gray);
  color: #666;
  cursor: not-allowed;
}

.button:not(.disabled):hover {
  opacity: 0.9;
}

.button.small {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.button.medium {
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.button.large {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
