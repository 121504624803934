@import "./styles/variables.css";

.main {
  min-height: 84vh;
  width: 83%;
  margin: auto;
  margin-bottom: 1rem;
}

@media (max-width: 48rem) {
  .main {
    width: 95%;
  }
}
